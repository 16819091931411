<template>
    <div class="grid">
        <div class="col-12">
            <div class="card text-center" v-if="permissionDenied">
                <h4>A kért oldal megtekintéséhez nincs jogosultsága!</h4>
            </div>
            <div class="card" v-if="!permissionDenied">
                <Toast/>
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                        </div>
                    </template>
                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="messages" v-model:selection="selectedMessages" dataKey="id"
                           :paginator="true" :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['subject']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Üzenetek <br> <strong><small>Összesen: {{ total }}</small></strong></h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="subject" header="Tárgy" :sortable="true" headerStyle="width:15%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Tárgy</span>
                            {{ slotProps.data.subject }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="email" header="Címzettek" :sortable="true" headerStyle="width:15%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Címzettek</span>
                            {{ slotProps.data.recipients.length }} címzett
                        </template>
                    </Column>
                    <Column field="createdAt" header="Kiküldés dátuma" :sortable="true"
                            headerStyle="width:15%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Kiküldés dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                    @click="show(slotProps.data)"/>
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen üzenet sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="messageDialog" :style="{width: '800px'}" header="Üzenet adatai" :modal="true"
                        class="p-fluid">
                    <div class="field col">
                        <label for="inventoryStatus">Címzettek</label>
                        <MultiSelect ::modelValue="recipients" v-model="message.recipients" :options="usersDropdown" :emptyMessage="'Nincs találat'"
                                     optionLabel="label" optionValue="value" :filter="true" filterBy="name" :showClear="true" placeholder="Kérlek válassz..."
                                     :class="{'p-invalid': submitted && !message.recipients}">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{slotProps.option.label}}</div>
                                </div>
                            </template>
                        </MultiSelect>
                        <small class="p-invalid" v-if="submitted && !message.recipients">Címzett megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="name">Tárgy</label>
                        <InputText id="subject" v-model.trim="message.subject" required="true"
                                   :class="{'p-invalid': submitted && !message.subject}"/>
                        <small class="p-invalid" v-if="submitted && !message.subject">Tárgy megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="message">Üzenet</label>
                        <ckeditor :editor="editor" v-model="messageText" :config="editorConfig"></ckeditor>
                        <small class="p-invalid" v-if="submitted && !messageText">Üzenet megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="attachment">Melléklet</label>
                        <FileUpload mode="basic" name="attachment" :customUpload="true" @uploader="onUpload"
                                    :auto="true"
                                    :multiple="true"
                                    :maxFileSize="10000000" class="p-button-outlined p-button-plain"
                                    :chooseLabel="attachment"></FileUpload>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Küldés" icon="pi pi-check" class="p-button-success" @click="saveMessage"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showMessageDialog" :style="{width: '800px'}" header="Üzenet adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading">
                        <ul class="list-none p-0 m-0 border-top-1 border-300">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">ID</div>
                                <div class="text-900 w-full md:w-5">{{ message.id }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Tárgy</div>
                                <div class="text-900 w-full md:w-5">{{ message.subject }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Kiküldés dátuma</div>
                                <div class="text-900 w-full md:w-5">{{ message.createdAt }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Címzettek</div>
                                <div class="text-900 w-full md:w-5">
                                    {{ message.recipients.map((recipient) => recipient).join(", ") }}
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Üzenet</div>
                                <div class="text-900 w-full md:w-5">
                                    <span v-html="message.message"></span>
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Melléklet</div>
                                <div class="text-900 w-full md:w-5">
                                    <ul>
                                        <li v-for="(link, index) in message.attachments" :key="index">
                                            <a :href="link" target="_blank">{{ extractLinkLabel(link) }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import MessageService from "@/service/MessageService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UserService from "@/service/UserService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            total: 0,
            messages: null,
            attachment: '',
            users: null,
            usersDropdown: [],
            recipients: [],
            messageDialog: false,
            showMessageDialog: false,
            message: {
                recipients: []
            },
            selectedMessages: null,
            filters: {},
            submitted: false,
            loading: true,
            permissionDenied: true,
            messageText: '',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|',
                        // 'alignment', '|',
                        'bold', 'italic',
                        'link', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
        }
    },
    messageService: null,
    userService: null,
    created() {
        this.messageService = new MessageService();
        this.userService = new UserService();
        this.initFilters();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));

        if (user.role === 1) {
            this.permissionDenied = true;
        } else {
            this.permissionDenied = false;
            this.getAll()
            this.getAllUser()
        }
    },
    methods: {
        onUpload(event) {
            this.attachment = '';
            delete this.message.attachment;
            const files = event.files.slice(0, 2);
            const base64Datas = [];
            const fileNames = [];
            files.forEach(file => {
                fileNames.push(this.$options.filters.truncate(file.name, 30));
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result;
                    base64Datas.push(base64Data);
                };
                reader.readAsDataURL(file);
            });
            this.attachment = fileNames.join(", ");
            this.message.attachment = base64Datas;
        },
        getAll() {
            this.messageService.getAll(0).then(response => {
                this.total = response.data.total
                this.messages = response.data.data
                this.loading = false;
            });
        },
        getAllUser() {
            this.userService.getAll().then(response => {
                this.users = response.data.data
                this.users.map((user) => {
                    this.usersDropdown.push({
                        label: user.name + ' - ' + user.email, value: user.email
                    })
                });

            });
        },
        openNew() {
            this.message = {};
            this.messageText = '';
            this.submitted = false;
            this.messageDialog = true;
        },
        hideDialog() {
            this.messageDialog = false;
            this.submitted = false;
            this.attachment = '';
        },
        saveMessage() {
            this.submitted = true;

            if (
                !this.message.subject ||
                !this.messageText ||
                !this.message.recipients
            ) {
                return;
            }

            this.loading = true;

            if (this.message.subject.trim()) {
                let message = {
                    recipients: this.message.recipients.map((recipient) => {
                        return recipient
                    }),
                    attachment: this.message.attachment,
                    subject: this.message.subject,
                    message: this.messageText,
                    type: 0,
                };

                this.messageService.create(message).then(() => {
                    this.messageDialog = false;
                    this.message = {};
                    this.attachment = '';
                    this.messageText = '';
                    this.getAll()

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sikeres művelet!',
                        detail: 'Az üzenet kiküldése sikeres volt.',
                        life: 5000
                    });

                    this.loading = false;
                }).catch(e => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: e.response.data.error,
                        life: 6000,
                    });

                    this.loading = false;
                });
            }
        },
        show(message) {
            this.message = {...message};
            this.showMessageDialog = true;
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.messages.length; i++) {
                if (this.messages[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 40 },
                { header: 'Tárgy', key: 'subject', width: 40 },
                { header: 'Címzettek', key: 'recipients', width: 10 },
                { header: 'Kiküldés dátuma', key: 'createdAt', width: 20 }
            ];

            const selectedProperties = worksheet.columns.map(column => column.key);
            const data = this.messages === null ? [] : this.messages;
            const modifiedData = data.map(obj =>
                selectedProperties.reduce((acc, prop) => {
                acc[prop] = obj[prop];
                if (prop === 'recipients') {
                    acc[prop] = obj[prop].length;
                }
                return acc;
                }, {})
            );

            modifiedData.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_uzenetek.xlsx`;
            a.download = filename;
            a.click();
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'subject': {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS
                }
            }
        },
        extractLinkLabel(link) {
            const parts = link.split('/');
            return parts[parts.length - 1];
        }
    },
    filters: {
        truncate: function (text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...'
            } else {
                return text
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>

<style>
.ck-editor__editable {
    min-height: 300px;
}
</style>
